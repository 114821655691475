import * as d3 from 'd3';
// import { formatBytes } from 'root/src/utils/common';
import { MetricsResponseMetricSeriesValue } from 'root/src/types/MetricsResponse';
import {
  HoverBoardCellData,
  RenderBandProps,
  RenderHoverBoardProps,
  RenderRhombusLinePlotProps,
  RenderTooltipProps,
  SPlotConfig,
} from './ChartTypes';

export const renderBand = ({
  container,
  axes,
  data,
  curve,
  gridConfig,
  lineConfig,
  dottedLinesConfig,
}: RenderBandProps) => {
  container.selectAll('*').remove();

  const [x, width] = axes.x.range();
  const [y, height] = axes.y.range();

  if (gridConfig.backgroundColor) {
    container
      .append('rect')
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', gridConfig.backgroundColor)
      .attr('rx', gridConfig?.gridRadius ?? 0)
      .attr('ry', gridConfig?.gridRadius ?? 0);
  }

  if (gridConfig.borderTopColor) {
    const [x1, x2] = axes.x.range();
    container
      .append('line')
      .attr('x1', x1)
      .attr('x2', x2)
      .attr('y1', y)
      .attr('y2', y)
      .style('stroke-width', 1)
      .style('stroke', gridConfig.borderTopColor);
  }

  if (gridConfig.borderBottomColor) {
    const [x1, x2] = axes.x.range();
    container
      .append('line')
      .attr('x1', x1 - 1)
      .attr('x2', x2 - 1)
      .attr('y1', y)
      .attr('y2', y)
      .style('stroke-width', 1)
      .style('stroke', gridConfig.borderBottomColor);
  }

  dottedLinesConfig?.lines.forEach((dl) => {
    container
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height - dl)
      .attr('y2', height - dl)
      .attr('stroke-dasharray', '5,5')
      .style('stroke-width', 1)
      .style('stroke', dottedLinesConfig.lineColor);
  });

  let lineYAxis = axes.y;

  if (lineConfig.threshold) {
    const yRange = axes.y.range();
    lineYAxis = axes.y.range([
      yRange[0] + lineConfig.threshold,
      yRange[1] - lineConfig.threshold,
    ]);
  }

  const line = d3
    .line<MetricsResponseMetricSeriesValue>()
    .defined((d) => !!d.value)
    .x((d) => axes.x(new Date(d.ts)))
    .y((d) => height - lineYAxis(d.value ?? 0))
    .curve(curve);

  container
    .append('path')
    .attr('fill', 'none')
    .attr('stroke', lineConfig.color)
    .attr('stroke-width', lineConfig.width)
    .attr('stroke-linecap', 'round')
    .attr('d', line(data));
};

export const renderHoverPlots = (
  container: d3.Selection<SVGGElement, unknown, null, undefined>,
  item: HoverBoardCellData | null,
  plotConfig: SPlotConfig[] | undefined
) => {
  if (plotConfig && plotConfig.length > 0) {
    plotConfig.forEach((plot) => {
      if (plot.type === 'CIRCLE') {
        const cx = plot.x(item?.ts ?? 0);
        const val = item?.plots[plot.yAccessor ?? ''];
        const cy = plot.y(val as unknown as d3.NumberValue);
        if (val) {
          container
            .append('circle')
            .attr('class', 'hover-plot')
            .attr('cx', cx)
            .attr('cy', cy)
            .attr('r', 5)
            .attr('stroke', plot.color(-1))
            .attr('stroke-width', 4)
            .attr('fill', 'white');
        }
      }
    });
  } else {
    container.selectAll('circle.hover-plot').remove();
  }
};

export const getTextWidth = (text: string, font = '500 12px Inter') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    return Math.ceil(context.measureText(text).width);
  }
  return -1;
};

const renderTooltip = ({
  container,
  width,
  height,
  xaxis,
  item,
  cellWidth,
  mouseY,
  plotData,
  toolTipConfig,
}: RenderTooltipProps) => {
  const toolTipY = Math.min(mouseY, height - 108);
  let toolTipX = xaxis(item.ts) + cellWidth;
  if (width - toolTipX < toolTipConfig.width) {
    toolTipX -= toolTipConfig.width + 2 * cellWidth;
  }
  const tooltip = container
    .append('g')
    .attr('id', 'tooltip')
    .attr('transform', `translate(${toolTipX},${toolTipY})`);
  tooltip
    .append('rect')
    .style('filter', 'drop-shadow(0px 4px 4px rgb(0 0 0 / 0.4)')
    .attr('x', 0)
    .attr('y', 0)
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('class', 'fill-gray-900')
    .attr('stroke', 'rgba(29, 40, 58, 0.6)')
    .attr('stroke-width', 1)
    .attr('width', toolTipConfig.width)
    .attr('height', toolTipConfig.height);

  tooltip
    .append('text')
    .attr('font-size', 12)
    .text(toolTipConfig.titleFormatter(item.ts.toString()))
    .attr('text-anchor', 'start')
    .attr('alignment-baseline', 'hanging')
    .attr('x', 8)
    .attr('y', 8)
    .attr('class', '!fill-foreground');

  // render legend shape
  const dataRows = Object.entries(plotData);

  if (dataRows.length > 0) {
    dataRows.forEach(([key, data], ind) => {
      const toolTipRow = tooltip
        .append('g')
        .attr('transform', `translate(0, ${24 + ind * 20})`);
      if (data?.type === 'CIRCLE') {
        toolTipRow
          .append('line')
          .attr('x1', 8)
          .attr('y1', 10)
          .attr('x2', 20)
          .attr('y2', 10)
          .attr('stroke', data.color)
          .attr('stroke-width', 2);
      } else if (data?.type === 'RHOMBUS') {
        toolTipRow
          .append('rect')
          .attr('x', 9)
          .attr('y', 5)
          .attr('width', 10)
          .attr('height', 10)
          .attr('fill', 'red')
          .attr('transform', 'rotate(45)')
          .attr('transform-origin', '15 9');
      }

      toolTipRow
        .append('text')
        .attr('font-size', 12)
        .text(`${key} :`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'hanging')
        .attr('x', 28)
        .attr('y', 4)
        .attr('class', '!fill-slate-300');
      toolTipRow
        .append('text')
        .attr('font-size', 12)
        .text(data.formatter ? data.formatter(item.plots[key]) : '-')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'hanging')
        .attr('x', (toolTipConfig?.columnWidth ?? 104) + 32)
        .attr('y', 4)
        .attr('font-weight', 600)
        .attr('class', '!fill-foreground');
    });
  }
};

const renderSelectedCell = (
  container: d3.Selection<SVGGElement, unknown, null, undefined>,
  item: HoverBoardCellData | null,
  plotConfig: SPlotConfig[] | undefined
) => {
  if (plotConfig && plotConfig.length > 0) {
    plotConfig.forEach((plot) => {
      if (plot.type === 'CIRCLE') {
        const cx = plot.x(item?.ts ?? 0);
        const val = item?.plots[plot.yAccessor ?? ''];
        const cy = plot.y(val as unknown as d3.NumberValue);
        if (val) {
          container
            .append('circle')
            .attr('class', 'selected-cell-plot')
            .attr('cx', cx)
            .attr('cy', cy)
            .attr('r', 4)
            .attr('stroke', 'white')
            .attr('stroke-width', 1)
            .attr('fill', plot.color(-1));
        }
      } else if (plot.type === 'RHOMBUS') {
        const cx = plot.x(item?.ts ?? 0);
        const val = +(item?.plots[plot.yAccessor ?? ''] ?? 0);
        if (val > 0) {
          container
            .append('rect')
            .attr('class', 'selected-cell-plot')
            .attr('x', cx)
            .attr('y', 5)
            .attr('width', 12)
            .attr('height', 12)
            .attr('fill', plot.color(val))
            .attr('stroke', 'white')
            .attr('stroke-width', 1.25)
            .attr('transform', 'rotate(45)')
            .attr('transform-origin', `${cx - 10} 10`);
        }
      }
    });
  }
};
export const renderHoverBoard = ({
  container,
  width,
  height,
  axes,
  data,
  plotConfig,
  toolTipConfig,
  onCellClick,
}: RenderHoverBoardProps) => {
  data.forEach((item, ind) => {
    let cellWidth = 0;
    if (data[ind + 1]) {
      cellWidth = axes.x(data[ind + 1].ts) - axes.x(item.ts);
    } else {
      cellWidth = width / data.length;
    }

    container
      .append('rect')
      .attr('x', axes.x(item.ts) - 0.5 * cellWidth)
      .attr('y', 0)
      .attr('width', cellWidth)
      .attr('height', height)
      .attr('fill', 'transparent')
      .on('click', function handleClick() {
        // const hasEvents = +item.plots.Events > 0;
        const currentlySelected = d3
          .select(this)
          .attr('class')
          .includes('selected-cell');

        d3.selectAll('.selected-cell')
          .attr('fill', 'transparent')
          .attr('class', '');
        d3.selectAll('.selected-cell-plot').remove();
        d3.select('#selected-cell-border').remove();
        if (currentlySelected) {
          if (onCellClick) {
            onCellClick(-1);
          }
          return;
        }
        d3.select(this)
          .attr('class', 'selected-cell cursor-pointer')
          .attr('fill', 'rgba(99, 102, 241, 0.2)');

        container
          .append('rect')
          .attr('id', 'selected-cell-border')
          .attr('x', axes.x(item.ts) - 0.5 * cellWidth)
          .attr('y', height - 2)
          .attr('width', cellWidth)
          .attr('height', 2)
          .attr('fill', '#818CF8');
        if (onCellClick) {
          renderSelectedCell(container, item, plotConfig);
          onCellClick(item.ts);
        }
      })
      .on(
        'mouseenter',
        function onMouseEnter(this: SVGRectElement, ev: unknown) {
          // const hasData = !Object.values(item.plots).every(
          //   (v) => v === '' || v === '0'
          // );

          // const hasEvents = +item.plots.Events > 0;

          const isSelected = d3
            .select(this)
            .attr('class')
            ?.includes('selected-cell');

          if (!isSelected) {
            d3.select(this)
              .attr('fill', 'rgba(99, 102, 241, 0.1)')
              .attr('class', 'cursor-pointer');
          }

          container
            .append('rect')
            .attr('id', 'hover-cell-border')
            .attr('x', axes.x(item.ts) - 0.5 * cellWidth)
            .attr('y', height - 2)
            .attr('width', cellWidth)
            .attr('height', 2)
            .attr('fill', '#818CF8');

          if (plotConfig) {
            if (!isSelected) {
              renderHoverPlots(container, item, plotConfig);
            }
            renderTooltip({
              container,
              toolTipConfig,
              width,
              cellWidth,
              xaxis: axes.x,
              item,
              height,
              mouseY: d3.pointer(ev)[1],
              plotData: plotConfig.reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.yAccessor]: {
                    type: curr.type,
                    color: curr.color,
                    formatter: curr.formatter,
                  },
                }),
                {}
              ),
            });
          }
        }
      )
      .on('mouseleave', function onMouseLeave() {
        const isSelected = d3
          .select(this)
          .attr('class')
          .includes('selected-cell');

        if (!isSelected) {
          d3.select(this).attr('fill', 'transparent');
        }
        renderHoverPlots(container, null, undefined);
        container.select('#tooltip').remove();
        container.select('#hover-cell-border').remove();
      });
  });
};

export const renderRhombusLinePlot = ({
  container,
  data,
  width,
  xScale,
  colorScale,
  referenceLineColor,
}: RenderRhombusLinePlotProps) => {
  container
    .append('line')
    .attr('x1', 0)
    .attr('y1', 8)
    .attr('x2', width)
    .attr('y2', 8)
    .style('stroke', referenceLineColor)
    .style('stroke-width', 1);
  data.forEach((ev) => {
    if (ev.value > 0) {
      container
        .append('rect')
        .attr('width', 12)
        .attr('height', 12)
        .attr('x', xScale(ev.ts) - 4)
        .attr('transform-origin', `${xScale(ev.ts) + 7} 7`)
        .attr('transform', 'rotate(45)')
        .attr('y', 5)
        .attr('fill', colorScale(ev.value));
    }
  });
};

export function SvgGradients() {
  return (
    <svg viewBox="0 0 10 10" style={{ height: 0, width: 0 }}>
      <defs>
        <linearGradient id="gradient-#6366f1=#10b981">
          <stop offset="5%" stopColor="#6366f1" />
          <stop offset="95%" stopColor="#10b981" />
        </linearGradient>
        <linearGradient id="gradient-#6366f1=#ec4899">
          <stop offset="5%" stopColor="#6366f1" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#6366f1=#ec4899">
          <stop offset="5%" stopColor="#6366f1" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#6366f1=#0ea5e9">
          <stop offset="5%" stopColor="#6366f1" />
          <stop offset="95%" stopColor="#0ea5e9" />
        </linearGradient>
        <linearGradient id="gradient-#6366f1=#d946ef">
          <stop offset="5%" stopColor="#6366f1" />
          <stop offset="95%" stopColor="#d946ef" />
        </linearGradient>
        <linearGradient id="gradient-#6366f1=#C084FC">
          <stop offset="5%" stopColor="#6366f1" />
          <stop offset="95%" stopColor="#C084FC" />
        </linearGradient>
        <linearGradient id="gradient-#10b981=#6366f1">
          <stop offset="5%" stopColor="#10b981" />
          <stop offset="95%" stopColor="#6366f1" />
        </linearGradient>
        <linearGradient id="gradient-#10b981=#ec4899">
          <stop offset="5%" stopColor="#10b981" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#10b981=#ec4899">
          <stop offset="5%" stopColor="#10b981" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#10b981=#0ea5e9">
          <stop offset="5%" stopColor="#10b981" />
          <stop offset="95%" stopColor="#0ea5e9" />
        </linearGradient>
        <linearGradient id="gradient-#10b981=#d946ef">
          <stop offset="5%" stopColor="#10b981" />
          <stop offset="95%" stopColor="#d946ef" />
        </linearGradient>
        <linearGradient id="gradient-#10b981=#C084FC">
          <stop offset="5%" stopColor="#10b981" />
          <stop offset="95%" stopColor="#C084FC" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#6366f1">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#6366f1" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#10b981">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#10b981" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#0ea5e9">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#0ea5e9" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#d946ef">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#d946ef" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#C084FC">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#C084FC" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#6366f1">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#6366f1" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#10b981">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#10b981" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#0ea5e9">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#0ea5e9" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#d946ef">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#d946ef" />
        </linearGradient>
        <linearGradient id="gradient-#ec4899=#C084FC">
          <stop offset="5%" stopColor="#ec4899" />
          <stop offset="95%" stopColor="#C084FC" />
        </linearGradient>
        <linearGradient id="gradient-#0ea5e9=#6366f1">
          <stop offset="5%" stopColor="#0ea5e9" />
          <stop offset="95%" stopColor="#6366f1" />
        </linearGradient>
        <linearGradient id="gradient-#0ea5e9=#10b981">
          <stop offset="5%" stopColor="#0ea5e9" />
          <stop offset="95%" stopColor="#10b981" />
        </linearGradient>
        <linearGradient id="gradient-#0ea5e9=#ec4899">
          <stop offset="5%" stopColor="#0ea5e9" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#0ea5e9=#ec4899">
          <stop offset="5%" stopColor="#0ea5e9" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#0ea5e9=#d946ef">
          <stop offset="5%" stopColor="#0ea5e9" />
          <stop offset="95%" stopColor="#d946ef" />
        </linearGradient>
        <linearGradient id="gradient-#0ea5e9=#C084FC">
          <stop offset="5%" stopColor="#0ea5e9" />
          <stop offset="95%" stopColor="#C084FC" />
        </linearGradient>
        <linearGradient id="gradient-#d946ef=#6366f1">
          <stop offset="5%" stopColor="#d946ef" />
          <stop offset="95%" stopColor="#6366f1" />
        </linearGradient>
        <linearGradient id="gradient-#d946ef=#10b981">
          <stop offset="5%" stopColor="#d946ef" />
          <stop offset="95%" stopColor="#10b981" />
        </linearGradient>
        <linearGradient id="gradient-#d946ef=#ec4899">
          <stop offset="5%" stopColor="#d946ef" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#d946ef=#ec4899">
          <stop offset="5%" stopColor="#d946ef" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#d946ef=#0ea5e9">
          <stop offset="5%" stopColor="#d946ef" />
          <stop offset="95%" stopColor="#0ea5e9" />
        </linearGradient>
        <linearGradient id="gradient-#d946ef=#C084FC">
          <stop offset="5%" stopColor="#d946ef" />
          <stop offset="95%" stopColor="#C084FC" />
        </linearGradient>
        <linearGradient id="gradient-#C084FC=#6366f1">
          <stop offset="5%" stopColor="#C084FC" />
          <stop offset="95%" stopColor="#6366f1" />
        </linearGradient>
        <linearGradient id="gradient-#C084FC=#10b981">
          <stop offset="5%" stopColor="#C084FC" />
          <stop offset="95%" stopColor="#10b981" />
        </linearGradient>
        <linearGradient id="gradient-#C084FC=#ec4899">
          <stop offset="5%" stopColor="#C084FC" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#C084FC=#ec4899">
          <stop offset="5%" stopColor="#C084FC" />
          <stop offset="95%" stopColor="#ec4899" />
        </linearGradient>
        <linearGradient id="gradient-#C084FC=#0ea5e9">
          <stop offset="5%" stopColor="#C084FC" />
          <stop offset="95%" stopColor="#0ea5e9" />
        </linearGradient>
        <linearGradient id="gradient-#C084FC=#d946ef">
          <stop offset="5%" stopColor="#C084FC" />
          <stop offset="95%" stopColor="#d946ef" />
        </linearGradient>
      </defs>
    </svg>
  );
}
