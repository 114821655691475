import './styles.scss';

import {
  BugPlay,
  Building2,
  ChevronDownIcon,
  ChevronRight,
  ChevronRightIcon,
  ChevronsLeft,
  Combine,
  Component,
  Database,
  GlassesIcon,
  LogOutIcon,
  MoreVertical,
  Settings,
  UserIcon,
  Plug,
  ShieldAlert,
  Files,
  BookOpenCheck,
  DollarSign,
  Settings2,
  FileClock,
} from 'lucide-react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useDescope, useSession, useUser } from '@descope/react-sdk';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/shadcn/collapsible';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/shadcn/dropdown-menu';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/shadcn/hover-card';

import { Button } from '@/components/shadcn/button';
import SingulrLogoDark from '../../../assets/common/SingulrLogoDark';
import SingulrLogoDarkIcon from '../../../assets/common/SingulrLogoDarkIcon';

interface PrimaryNavigationProps {
  open: boolean;
  toggleExpand: () => void;
}
enum subMenuKeys {
  CONTENT = 'CONTENT',
  MANAGE = 'MANAGE',
  NONE = 'NONE',
}
function PrimaryNavigation({ open, toggleExpand }: PrimaryNavigationProps) {
  const { logout } = useDescope();
  const { isAuthenticated } = useSession();
  const { user, isUserLoading } = useUser();

  const isDebugUIEnabled = user?.customAttributes?.debug ?? false;
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const [activeSubMenu, setActiveSubMenu] = useState<subMenuKeys>(
    subMenuKeys.NONE
  );
  const { pathname } = useLocation();

  const ProfileMenuDropDownContent = useMemo(
    () =>
      function () {
        return (
          <DropdownMenuContent align="end" side="right">
            {isDebugUIEnabled ? (
              <>
                <DropdownMenuItem
                  className="gap-2 items-center cursor-pointer !outline-none"
                  onSelect={() => {
                    navigate('/debug');
                  }}
                >
                  <BugPlay className="w-6" />
                  <span>Singulr Debug panel</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="gap-2 items-center cursor-pointer !outline-none"
                  onSelect={() => {
                    navigate('/playground');
                  }}
                >
                  <BugPlay className="w-6" />
                  <span>Singulr Playground</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="gap-2 items-center cursor-pointer !outline-none"
                  onSelect={() => {
                    navigate('/trust-v2/ai_service_definitions_table_page');
                  }}
                >
                  <BugPlay className="w-6" />
                  <span>TrustV2</span>
                </DropdownMenuItem>
              </>
            ) : null}

            <DropdownMenuItem
              className="gap-2 items-center cursor-pointer !outline-none !text-red-500"
              onClick={handleLogout}
              onSelect={(e: Event) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              <LogOutIcon className="w-6" />
              <span>Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        );
      },
    [isDebugUIEnabled]
  );
  return (
    <div
      className={`h-full relative transition-all duration-300 flex flex-col text-slate-400 justify-between  ${
        open ? 'w-[240px] max-w-[240px] px-3' : 'w-[82px] max-w-[82px] px-3'
      } transition-all  items-start  py-[10px]`}
    >
      <ul
        className={`flex flex-col justify-center w-full items-start ${
          open ? 'pl-3' : 'px-3'
        }`}
      >
        <li
          className={`flex gap-1 items-center rounded-sm h-[48px] mb-6 ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          }`}
        >
          <NavLink to="/assets">
            {open ? (
              <SingulrLogoDark className="!min-w-[100px] !h-8" />
            ) : (
              <SingulrLogoDarkIcon className="px-3 w-full" />
            )}
          </NavLink>

          <div
            className={`bg-slate-800 transition-all rounded-[3px] w-6 h-6 flex items-center justify-center absolute  cursor-pointer ${
              open ? 'right-6' : '-right-3'
            }`}
            onClick={toggleExpand}
          >
            <ChevronsLeft
              className={`w-4 transition-all ${
                open ? 'rotate-0' : 'rotate-180'
              }`}
            />
          </div>
        </li>
        {/* <li
          className={`flex gap-1 items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          }
          ${pathname === '/dashboard' ? 'bg-slate-800 !text-white' : ''}`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger
              asChild
              className="flex w-full h-full items-center"
            >
              <NavLink
                className={`flex w-full h-full items-center ${
                  open ? ' justify-start' : 'justify-center'
                } ${pathname === '/dashboard' ? 'text-white' : ''}`}
                to={'/dashboard'}
              >
                <HomeIcon className="h-5 w-8" />
                {open && (
                  <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                    Home
                  </span>
                )}
              </NavLink>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                Home
              </HoverCardContent>
            )}
          </HoverCard>
        </li> */}

        <li
          className={`flex gap-1 items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          }
          ${pathname.includes('/assets') ? 'bg-slate-800 !text-white' : ''}`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger
              asChild
              className="flex w-full h-full items-center"
            >
              <NavLink
                className={`flex w-full h-full items-center ${
                  open ? ' justify-start' : 'justify-center'
                } ${pathname.includes('/assets') ? 'text-white' : ''}`}
                to="/assets"
              >
                <GlassesIcon className="h-5 w-8" />
                {open && (
                  <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                    AI Discovery
                  </span>
                )}
              </NavLink>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                AI Discovery
              </HoverCardContent>
            )}
          </HoverCard>
        </li>

        <li
          className={`flex gap-1 items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          }
          ${pathname.includes('/risks') ? 'bg-slate-800 !text-white' : ''}`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger
              asChild
              className="flex w-full h-full items-center"
            >
              <NavLink
                className={`flex w-full h-full items-center ${
                  open ? ' justify-start' : 'justify-center'
                } ${pathname.includes('/risks') ? 'text-white' : ''}`}
                to="/risks"
              >
                <ShieldAlert className="h-5 w-8" />
                {open && (
                  <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                    AI Risks
                  </span>
                )}
              </NavLink>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                AI Risks
              </HoverCardContent>
            )}
          </HoverCard>
        </li>

        <li
          className={`flex gap-1 items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          }
          ${pathname.includes('/content') ? 'bg-slate-800 !text-white' : ''}`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger
              asChild
              className="flex w-full h-full items-center"
            >
              <NavLink
                className={`flex w-full h-full items-center ${
                  open ? ' justify-start' : 'justify-center'
                } ${pathname.includes('/content') ? 'text-white' : ''}`}
                to="/content"
              >
                <Files className="h-5 w-8" />
                {open && (
                  <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                    AI Content
                  </span>
                )}
              </NavLink>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                AI Content
              </HoverCardContent>
            )}
          </HoverCard>
        </li>
        <li
          className={`flex gap-1 items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          }
          ${pathname.includes('/policies') ? 'bg-slate-800 !text-white' : ''}`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger
              asChild
              className="flex w-full h-full items-center"
            >
              <NavLink
                className={`flex w-full h-full items-center ${
                  open ? ' justify-start' : 'justify-center'
                } ${pathname.includes('/policies') ? 'text-white' : ''}`}
                to="/policies"
              >
                <BookOpenCheck className="h-5 w-8" />
                {open && (
                  <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                    AI Policies
                  </span>
                )}
              </NavLink>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                AI Policies
              </HoverCardContent>
            )}
          </HoverCard>
        </li>

        <li
          className={`flex gap-1 items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          }
          ${pathname.includes('/cost') ? 'bg-slate-800 !text-white' : ''}`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger
              asChild
              className="flex w-full h-full items-center"
            >
              <NavLink
                className={`flex w-full h-full items-center ${
                  open ? ' justify-start' : 'justify-center'
                } ${pathname.includes('/cost') ? 'text-white' : ''}`}
                to="/cost"
              >
                <DollarSign className="h-5 w-8" />
                {open && (
                  <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                    AI Cost
                  </span>
                )}
              </NavLink>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                AI Cost
              </HoverCardContent>
            )}
          </HoverCard>
        </li>

        <li
          className={`flex gap-1 items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
            open
              ? 'w-full ml-0 justify-start pl-2'
              : 'w-[58px] -ml-[10px] justify-center'
          } ${
            pathname.includes('/trust-feed') ? 'bg-slate-800 !text-white' : ''
          }`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger
              asChild
              className="flex w-full h-full items-center"
            >
              <NavLink
                className={`flex w-full h-full items-center ${
                  open ? ' justify-start' : 'justify-center'
                } `}
                to="/trust-feed/ai_service_definitions_table_page"
              >
                <Building2 className="h-5 w-8" />
                {open && (
                  <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                    Trust Feed
                  </span>
                )}
              </NavLink>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                Trust Feed
              </HoverCardContent>
            )}
          </HoverCard>
        </li>
        <li
          className={`flex gap-1 items-center  ${
            open
              ? 'w-full ml-0 justify-between'
              : 'w-[58px] -ml-[10px] justify-center'
          }`}
        >
          <HoverCard openDelay={0} closeDelay={0}>
            <HoverCardTrigger asChild className="flex w-full h-full">
              <Collapsible
                open={activeSubMenu === subMenuKeys.MANAGE}
                onOpenChange={(isOpen) => {
                  setActiveSubMenu(
                    isOpen ? subMenuKeys.MANAGE : subMenuKeys.NONE
                  );
                }}
                className="w-full flex flex-col items-start"
              >
                <CollapsibleTrigger
                  className={`w-full flex h-[48px]  hover:bg-muted rounded-sm hover:text-white items-center justify-between ${
                    [
                      '/datasources',
                      '/datasources/new',
                      '/policies',
                      '/users',
                      '/collectors',
                      '/all-assets',
                      '/integrations',
                    ].some((endpoint) => pathname.includes(endpoint))
                      ? 'text-white'
                      : ''
                  }`}
                  onClick={() => {
                    if (!open) {
                      toggleExpand();
                    }
                  }}
                >
                  <div className={`flex ${open ? '' : 'mx-auto'}`}>
                    <div className="flex items-center">
                      <Settings className="h-5 w-8 ml-2" />
                      {open ? null : <ChevronRight className="h-3 w-3 -ml-1" />}
                    </div>
                    {open && (
                      <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                        Manage
                      </span>
                    )}
                  </div>
                  {open ? (
                    activeSubMenu === subMenuKeys.MANAGE ? (
                      <ChevronDownIcon className="w-4 mr-3" />
                    ) : (
                      <ChevronRightIcon className="w-4 mr-3" />
                    )
                  ) : null}
                </CollapsibleTrigger>
                {open && (
                  <CollapsibleContent
                    className="pl-4 !w-full flex"
                    style={{ width: '100%' }}
                  >
                    <ul className="w-full">
                      <li
                        className={`flex items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
                          open
                            ? 'w-full ml-0 justify-start pl-2'
                            : 'w-[58px] -ml-[10px] justify-center'
                        } ${
                          pathname.includes('/all-assets')
                            ? 'bg-slate-800 !text-white'
                            : ''
                        }`}
                      >
                        <NavLink
                          className={`flex gap-1 w-full h-full items-center ${
                            open ? ' justify-start' : 'justify-center'
                          }`}
                          to="/all-assets"
                        >
                          <Component className="h-5 w-6" />
                          {open && (
                            <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                              All Assets
                            </span>
                          )}
                        </NavLink>
                      </li>

                      <li
                        className={`flex items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
                          open
                            ? 'w-full ml-0 justify-start pl-2'
                            : 'w-[58px] -ml-[10px] justify-center'
                        } ${
                          pathname.includes('/datasources')
                            ? 'bg-slate-800 !text-white'
                            : ''
                        }`}
                      >
                        <NavLink
                          className={`flex gap-1 w-full h-full items-center ${
                            open ? ' justify-start' : 'justify-center'
                          }`}
                          to="/datasources"
                        >
                          <Database className="h-5 w-6" />
                          {open && (
                            <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                              Data Sources
                            </span>
                          )}
                        </NavLink>
                      </li>
                      <li
                        className={`flex items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
                          open
                            ? 'w-full ml-0 justify-start pl-2'
                            : 'w-[58px] -ml-[10px] justify-center'
                        } ${
                          pathname.includes('/collectors')
                            ? 'bg-slate-800 !text-white'
                            : ''
                        }`}
                      >
                        <NavLink
                          className={`flex gap-1 w-full h-full items-center ${
                            open ? ' justify-start' : 'justify-center'
                          }`}
                          to="/collectors"
                        >
                          <Combine className="h-5 w-6" />
                          {open && (
                            <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                              Collectors
                            </span>
                          )}
                        </NavLink>
                      </li>
                      <li
                        className={`flex items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
                          open
                            ? 'w-full ml-0 justify-start pl-2'
                            : 'w-[58px] -ml-[10px] justify-center'
                        } ${
                          pathname === '/integrations'
                            ? 'bg-slate-800 !text-white'
                            : ''
                        }`}
                      >
                        <NavLink
                          className={`flex gap-1 w-full h-full items-center ${
                            open ? ' justify-start' : 'justify-center'
                          }`}
                          to="/integrations"
                        >
                          <Plug className="h-6 w-7" />
                          {open && (
                            <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                              Integrations
                            </span>
                          )}
                        </NavLink>
                      </li>
                      <li
                        className={`flex items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
                          open
                            ? 'w-full ml-0 justify-start pl-2'
                            : 'w-[58px] -ml-[10px] justify-center'
                        } ${
                          pathname === '/audit-logs'
                            ? 'bg-slate-800 !text-white'
                            : ''
                        }`}
                      >
                        <NavLink
                          className={`flex gap-1 w-full h-full items-center ${
                            open ? ' justify-start' : 'justify-center'
                          }`}
                          to="/audit-logs"
                        >
                          <FileClock className="h-6 w-7" />
                          {open && (
                            <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                              Audit Logs
                            </span>
                          )}
                        </NavLink>
                      </li>
                      <li
                        className={`flex items-center  hover:bg-muted rounded-sm hover:text-white h-[48px] ${
                          open
                            ? 'w-full ml-0 justify-start pl-2'
                            : 'w-[58px] -ml-[10px] justify-center'
                        } ${
                          pathname === '/settings'
                            ? 'bg-slate-800 !text-white'
                            : ''
                        }`}
                      >
                        <NavLink
                          className={`flex gap-1 w-full h-full items-center ${
                            open ? ' justify-start' : 'justify-center'
                          }`}
                          to="/settings"
                        >
                          <Settings2 className="h-6 w-7" />
                          {open && (
                            <span className="animate-[navSlide_0.5s_ease_1] text-sm">
                              Settings
                            </span>
                          )}
                        </NavLink>
                      </li>
                    </ul>
                  </CollapsibleContent>
                )}
              </Collapsible>
            </HoverCardTrigger>
            {open ? null : (
              <HoverCardContent className="w-auto p-1 border-0" side="right">
                Manage
              </HoverCardContent>
            )}
          </HoverCard>
        </li>
      </ul>

      <div className="w-full">
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="">
            <div
              className={`w-full flex gap-2 items-center h-14 bg-gray-900 rounded-md ${
                open
                  ? 'justify-start p-3 pr-0'
                  : 'justify-start p-3 cursor-pointer'
              }`}
            >
              {isAuthenticated ? (
                !isUserLoading && (user?.name || user?.email) ? (
                  <div className="min-w-8 min-h-8 flex border bg-primary text-white rounded-full items-center justify-center capitalize cursor-default">
                    {((user?.name || user?.email) ?? '')[0]}
                  </div>
                ) : (
                  <UserIcon className="w-4 animate-pulse" />
                )
              ) : null}
              {open ? (
                <div className="animate-[navSlide_0.5s_ease_1] text-xs w-full flex justify-between items-center pr-1">
                  <div>
                    <p className="text-foreground">{user?.name ?? ''}</p>
                    <p className="text-muted-foreground">{user?.email ?? ''}</p>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild className="cursor-pointer">
                      <Button
                        variant="ghost"
                        className="w-10 rounded-full p-0 cursor-pointer"
                      >
                        <MoreVertical className="h-4 cursor-pointer" />
                      </Button>
                    </DropdownMenuTrigger>
                    <ProfileMenuDropDownContent />
                  </DropdownMenu>
                </div>
              ) : null}
            </div>
          </DropdownMenuTrigger>
          {!open ? <ProfileMenuDropDownContent /> : null}
        </DropdownMenu>
      </div>
    </div>
  );
}

export default PrimaryNavigation;
