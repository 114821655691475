import axios, { InternalAxiosRequestConfig } from 'axios';

import { getSessionToken } from '@descope/react-sdk';

const baseURL = `${import.meta.env.VITE_BASE_URL}`;

const apiClient = axios.create({ baseURL });
apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getSessionToken();
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  } as unknown as InternalAxiosRequestConfig;
});
export default apiClient;

export const eventsBaseURL = `${import.meta.env.VITE_BASE_URL}`;
export const endpoints: Record<string, string> = {
  LIST_EVENTS: '/api/v1/events',
  LAYOUT_REQUEST: '/api/v1/components/',
  PROMT_COMPLETION_LIST:
    '/api/v1/contents/?content_entity_type=PROMPT_COMPLETION_PAIR',
  PROMT_COMPLETION_ANNOTATION: '/api/v1/contents/',
};
