import { getSessionToken } from '@descope/react-sdk';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { DefaultApi } from '../generated';

const baseURL = `${import.meta.env.VITE_BASE_URL}/api/v1`;

const apiClient = axios.create({ baseURL });
apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getSessionToken();
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  } as unknown as InternalAxiosRequestConfig;
});

const allApi = new DefaultApi(
  {
    basePath: baseURL,
    isJsonMime: () => true,
  },
  baseURL,
  apiClient
);

export default allApi;
