import { IconProps } from '../icons';

function SingulrLogoDarkIcon(props: IconProps) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.2267 7.46326C21.8158 6.48102 21.4934 5.19243 20.4161 4.78575C18.7566 4.15934 16.97 3.89935 15.1825 4.03505C12.6974 4.2237 10.3316 5.16794 8.40806 6.73885C6.48454 8.30976 5.09703 10.4308 4.43497 12.8124C3.7729 15.1939 3.86854 17.72 4.70883 20.0457C5.54911 22.3714 7.09309 24.3834 9.12999 25.807C11.1669 27.2306 13.5974 27.9965 16.0898 28C18.5822 28.0035 21.0149 27.2445 23.0559 25.8267C24.5239 24.8068 25.7379 23.4818 26.6189 21.9537C27.1909 20.9616 26.6146 19.7631 25.5304 19.3748L25.315 19.2977C24.2307 18.9094 23.0544 19.4906 22.3867 20.422C21.8801 21.1288 21.2528 21.7489 20.5283 22.2523C19.2297 23.1544 17.6818 23.6373 16.0961 23.635C14.5103 23.6328 12.9638 23.1455 11.6679 22.2398C10.3719 21.334 9.38952 20.0538 8.85488 18.5741C8.32025 17.0944 8.2594 15.4871 8.68064 13.9719C9.10188 12.4566 9.98469 11.1071 11.2085 10.1076C12.4324 9.10806 13.9376 8.50729 15.5188 8.38726C16.401 8.32028 17.2829 8.4048 18.1269 8.63211C19.2394 8.93173 20.5206 8.64064 21.1097 7.6584L21.2267 7.46326Z"
        fill="#F1F1F1"
      />
      <path
        d="M28.6316 12.8966C28.6316 14.7248 27.1363 16.2069 25.2917 16.2069C23.4471 16.2069 21.9518 14.7248 21.9518 12.8966C21.9518 11.0683 23.4471 9.58622 25.2917 9.58622C27.1363 9.58622 28.6316 11.0683 28.6316 12.8966Z"
        fill="#84CC16"
      />
    </svg>
  );
}

export default SingulrLogoDarkIcon;
