import './App.scss';

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSession, useUser } from '@descope/react-sdk';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import clsx from 'clsx';
import { privateRoutesConfig, publicRoutesConfig } from './routes';

import { AppConfigProvider } from './contexts/AppConfig';
import { LOGIN_URL } from './constants/index';
import { PrimaryNavigation } from './components/Navigations/PrimaryNavigation';
import Toaster from '@/components/shadcn/toaster';
import { TooltipProvider } from '@/components/shadcn/tooltip';
import FileNotFound from './layout/RouteNotFound';
import PublicRouteNotFound from './layout/PublicRouteNotFound';
import ErrorBoundary from './utils/errorBoundary';
import { SvgGradients } from '@/components/singulr/ChartUtils';

export const queryClientRQ = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false },
  },
});

function App() {
  const { isAuthenticated, isSessionLoading } = useSession();

  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pageContainerClasses = clsx({
    'flex transition-all duration-300 pb-4 pr-4 pl-6 bg-[--page]': true,
    'w-[calc(100vw-240px)] max-w-[calc(100vw-240px)]': !!navExpanded,
    'w-[calc(100vw-82px)] max-w-[calc(100vw-82px)]': !navExpanded,
  });
  const authIgnoreRoutes = publicRoutesConfig.map((r) => r.path);

  const { user } = useUser();

  const pseudoPublicPage = pathname === '/welcome';

  useEffect(() => {
    if (!isAuthenticated && !isSessionLoading) {
      if (!authIgnoreRoutes.includes(pathname)) {
        navigate(LOGIN_URL);
      }
    }
  }, [
    pathname,
    isAuthenticated,
    isSessionLoading,
    user,
    authIgnoreRoutes,
    navigate,
  ]);

  const getRoutes = () => {
    if (isAuthenticated && !pseudoPublicPage) {
      return (
        <div className="flex h-screen">
          <PrimaryNavigation
            open={navExpanded}
            toggleExpand={() => {
              setNavExpanded(!navExpanded);
            }}
          />
          <div className={pageContainerClasses}>
            <Routes>
              {privateRoutesConfig.map((route) => (
                <Route
                  path={route.path}
                  Component={route.component}
                  key={route.path}
                />
              ))}
              <Route path="/" element={<Navigate to="/discovery/assets" />} />
              <Route path="*" Component={FileNotFound} />
            </Routes>
          </div>
        </div>
      );
    }
    return (
      <Routes>
        {publicRoutesConfig.map((route) => (
          <Route
            path={route.path}
            Component={route.component}
            key={route.path}
          />
        ))}
        <Route path="*" Component={PublicRouteNotFound} />
      </Routes>
    );
  };

  return (
    <ErrorBoundary>
      <SvgGradients />
      <QueryClientProvider client={queryClientRQ}>
        <TooltipProvider delayDuration={0}>
          <AppConfigProvider>
            <div className="flex flex-col h-screen">
              {isSessionLoading ? (
                <div className=" h-full w-ful flex items-center justify-center">
                  <div className="sui-loader" />
                </div>
              ) : (
                getRoutes()
              )}
              <Toaster />
            </div>
          </AppConfigProvider>
        </TooltipProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
