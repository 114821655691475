import { useCallback, useState } from 'react';
import { Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/shadcn/dialog';

import { Button } from '@/components/shadcn/button';
import { useToast } from '@/components/shadcn/use-toast';

interface DisconnectDialogueProps {
  unSetConnection: (() => Promise<void>) | undefined;
  disconnecting: boolean;
}
function DisconnectDialogue({
  unSetConnection,
  disconnecting,
}: DisconnectDialogueProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { toast } = useToast();
  const handleDisconnect = useCallback(async () => {
    if (!unSetConnection) {
      return;
    }
    try {
      await unSetConnection();
      toast({
        variant: 'success',
        title: 'Disconnected from the service!',
        description: 'You can reconnect to it by clicking "connect" button',
      });
      setOpen(false);
    } catch (e: any) {
      toast({
        variant: 'destructive',
        title: 'We could not disconnect you',
        description: 'Something went wrong! We are looking at it!',
      });
      console.error(e);
    }
  }, []);
  return (
    <Dialog
      open={open}
      onOpenChange={(op) => {
        setOpen(op);
      }}
    >
      <DialogTrigger asChild>
        <Button variant="ghost" className="border border-indigo-500">
          Disconnect
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[600px]">
        <DialogTitle>Are you sure you want to disconnect?</DialogTitle>
        <DialogDescription>
          This will remove Jira integration.
        </DialogDescription>
        <DialogFooter className="gap-2">
          <Button variant="ghost" className="border">
            Cancel
          </Button>
          <Button
            disabled={disconnecting}
            onClick={handleDisconnect}
            className="gap-2"
          >
            {disconnecting ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : null}
            Disconnect
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default DisconnectDialogue;
