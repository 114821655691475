import { ComponentType, EntityTypeEnum, UiComponent } from '../types/response';

export const emptyUIComponent: UiComponent = {
  id: 'EMPTY_COMPONENT',
  type: ComponentType.EMPTY_COMPONENT,
  title: 'Loading...',
  description: '',
  queries: [],
  childComponents: [],
  group: '',
  container: true,
  linkedComponentIds: [],
  default: true,
  linkedPageId: '',
};

export const EntityTypeLabelsMap: Record<EntityTypeEnum, string> = {
  [EntityTypeEnum.ENTITY_TYPE_UNKNOWN]: 'Entity Type Unknown',
  [EntityTypeEnum.MACHINE]: 'Machine',
  [EntityTypeEnum.PROCESS_INSTANCE]: 'Process Instance',
  [EntityTypeEnum.NETWORK_IDENTITY]: 'Network Identity',
  [EntityTypeEnum.NETWORK_INTERFACE]: 'Network Interface',
  [EntityTypeEnum.USER]: 'User',
  [EntityTypeEnum.GROUP]: 'Group',
  [EntityTypeEnum.SPAN]: 'Span',
  [EntityTypeEnum.TRACE]: 'Trace',
  [EntityTypeEnum.CONVERSATION]: 'Conversation',
  [EntityTypeEnum.COMPONENT]: 'Component',
  [EntityTypeEnum.FRAMEWORK]: 'Framework',
  [EntityTypeEnum.CHAIN_FUNCTION]: 'Chain Function',
  [EntityTypeEnum.LLM_FUNCTION]: 'LLM Function',
  [EntityTypeEnum.PROMPT_FUNCTION]: 'Prompt Function',
  [EntityTypeEnum.APPLICATION]: 'Application',
  [EntityTypeEnum.RETRIEVER_FUNCTION]: 'Retriever Function',
  [EntityTypeEnum.RETRIEVAL_STORE]: 'Retrieval Store',
  [EntityTypeEnum.DNS_SERVICE]: 'DNS Service',
  [EntityTypeEnum.VM]: 'VM',
  [EntityTypeEnum.AWS_VM]: 'AWS VM',
  [EntityTypeEnum.VPC]: 'VPC',
  [EntityTypeEnum.AWS_VPC]: 'AWS VPC',
  [EntityTypeEnum.IP]: 'IP',
  [EntityTypeEnum.CLOUD_USER]: 'Cloud User',
  [EntityTypeEnum.AWS_CLOUD_USER]: 'AWS Cloud User',
  [EntityTypeEnum.STORAGE_BUCKET]: 'Storage Bucket',
  [EntityTypeEnum.S3_STORAGE_BUCKET]: 'S3 Storage Bucket',
  [EntityTypeEnum.DOCUMENT_CONTAINER]: 'Document Container',
  [EntityTypeEnum.DOCUMENT]: 'Document',
  [EntityTypeEnum.VECTOR_DB]: 'Vector DB',
  [EntityTypeEnum.VECTOR_DB_INDEX]: 'Vector DB Index',
  [EntityTypeEnum.VECTOR_DB_COLLECTION]: 'Vector DB Collection',
  [EntityTypeEnum.VECTOR_DB_ENVIRONMENT]: 'Vector DB Environment',
  [EntityTypeEnum.PINECONE_VECTOR_DB]: 'Pinecone Vector DB',
  [EntityTypeEnum.PINECONE_VECTOR_DB_INDEX]: 'Pinecone Vector DB Index',
  [EntityTypeEnum.PINECONE_VECTOR_DB_COLLECTION]:
    'Pinecone Vector DB Collection',
  [EntityTypeEnum.PINECONE_VECTOR_DB_ENVIRONMENT]:
    'Pinecone Vector DB Environment',
  [EntityTypeEnum.ML_MODEL]: 'ML Model',
  [EntityTypeEnum.ML_DATA_SOURCE]: 'ML Data Source',
  [EntityTypeEnum.ML_MODEL_SOURCE]: 'ML Model Source',
  [EntityTypeEnum.ML_MODEL_ENDPOINT]: 'ML Model Endpoint',
  [EntityTypeEnum.AWS_ML_MODEL_ENDPOINT]: 'AWS ML Model Endpoint',
  [EntityTypeEnum.ML_MODEL_INSTANCE]: 'ML Model Instance',
  [EntityTypeEnum.AWS_ML_MODEL_INSTANCE]: 'AWS ML Model Instance',
  [EntityTypeEnum.COMPUTE_SPEC]: 'Compute Spec',
  [EntityTypeEnum.AWS_COMPUTE_SPEC]: 'AWS Compute Spec',
  [EntityTypeEnum.ML_PLATFORM]: 'ML Platform',
  [EntityTypeEnum.ML_PLATFORM_POLICY]: 'ML Platform Policy',
  [EntityTypeEnum.ML_MODEL_DEPLOYMENT]: 'ML Model Deployment',
  [EntityTypeEnum.ENDPOINT]: 'Endpoint',
  [EntityTypeEnum.CONTENT_POLICY]: 'Content Policy',
  [EntityTypeEnum.CONTENT_FILTER]: 'Content Filter',
  [EntityTypeEnum.PROMPT_CONTENT_FILTER]: 'Prompt Content Filter',
  [EntityTypeEnum.COMPLETION_CONTENT_FILTER]: 'Completion Content Filter',
  [EntityTypeEnum.ML_MODEL_REPO]: 'ML Model Repo',
  [EntityTypeEnum.HF_ML_MODEL_REPO]: 'HF ML Model Repo',
  [EntityTypeEnum.ML_DATA_REPO]: 'ML Data Repo',
  [EntityTypeEnum.HF_ML_DATA_REPO]: 'HF ML Data Repo',
  [EntityTypeEnum.ML_MODEL_SET]: 'ML Model Set',
  [EntityTypeEnum.ML_DATA_SET]: 'ML Data Set',
  [EntityTypeEnum.AI_SERVICE_DEFINITION]: 'AI Service Definition',
  [EntityTypeEnum.JAILBREAK_DEFINITION]: 'Jailbreak Definition',
  [EntityTypeEnum.LLM_DEFINITION]: 'LLM Definition',
  [EntityTypeEnum.LLM_FAMILY_DEFINITION]: 'LLM Family Definition',
  [EntityTypeEnum.ML_MODEL_DEFINITION]: 'ML Model Definition',
  [EntityTypeEnum.ML_DATA_SET_DEFINITION]: 'ML Data Set Definition',
  [EntityTypeEnum.ML_LIBRARY_DEFINITION]: 'ML Library Definition',
  [EntityTypeEnum.PUBLISHER_DEFINITION]: 'Publisher Definition',
  [EntityTypeEnum.AI_API_ENDPOINT_DEFINITION]: 'AI API Endpoint Definition',
  [EntityTypeEnum.AI_SERVICE]: 'AI Service',
  [EntityTypeEnum.LLM]: 'LLM',
  [EntityTypeEnum.K8S_POD]: 'K8s Pod',
  [EntityTypeEnum.HTTP_SERVICE]: 'HTTP Service',
  [EntityTypeEnum.IAM_ROLE]: 'IAM Role',
  [EntityTypeEnum.IAM_USER]: 'IAM User',
  [EntityTypeEnum.IAM_USER_GROUP]: 'IAM User Group',
  [EntityTypeEnum.IAM_POLICY]: 'IAM Policy',
  [EntityTypeEnum.IAM_PERMISSION]: 'IAM Permission',
  [EntityTypeEnum.PRIMITIVE_ENTITY_ID_NAME]: 'Primitive Entity ID Name',
  [EntityTypeEnum.PRIMITIVE_STRING]: 'Primitive String',
  [EntityTypeEnum.PRIMITIVE_INT]: 'Primitive Int',
  [EntityTypeEnum.PRIMITIVE_BOOLEAN]: 'Primitive Boolean',
  [EntityTypeEnum.EVENT_LICENSE_NON_COMMERCIAL]:
    'Event: License Non-Commercial',
  [EntityTypeEnum.EVENT_NON_ENCRYPTED_VECTOR_DB]:
    'Event: Non-Encrypted Vector DB',
  [EntityTypeEnum.EVENT_UNAPPROVED_MODEL]: 'Event: Unapproved Model',
  [EntityTypeEnum.EVENT_AI_SERVICE_POTENTIAL_DATA_LEAK]:
    'Event: AI Service Potential Data Leak',
  [EntityTypeEnum.EVENT_AI_SERVICE_BLOCKED_USAGE]:
    'Event: AI Service Blocked Usage',
  [EntityTypeEnum.EVENT_VM_GPU_IDLE]: 'Event: VM GPU Idle',
  [EntityTypeEnum.EVENT_MODEL_DEPLOYED_FROM_UNTRUSTED_VENDOR]:
    'Event: Model Deployed from Untrusted Vendor',
  [EntityTypeEnum.EVENT_MODEL_TRAINED_ON_BIASED_DATA]:
    'Event: Model Trained on Biased Data',
  [EntityTypeEnum.EVENT_MODEL_TRAINED_ON_TOXIC_DATA]:
    'Event: Model Trained on Toxic Data',
  [EntityTypeEnum.EVENT_MODEL_WITH_WELL_KNOWN_JAILBREAKS]:
    'Event: Model with Well-Known Jailbreaks',
  [EntityTypeEnum.EVENT_VM_GPU]: 'Event: VM GPU',
  [EntityTypeEnum.EVENT_MODEL_FROM_PRIVATE_MODEL_CARD]:
    'Event: Model from Private Model Card',
  [EntityTypeEnum.EVENT_MODEL_WEAK_CONTENT_POLICY]:
    'Event: Model Weak Content Policy',
  [EntityTypeEnum.EVENT_ML_PLATFORM_DATA_ISOLATION_DISABLED]:
    'Event: ML Platform Data Isolation Disabled',
  [EntityTypeEnum.EVENT_ML_PLATFORM_NETWORK_ISOLATION_DISABLED]:
    'Event: ML Platform Network Isolation Disabled',
  [EntityTypeEnum.EVENT_ML_PLATFORM_PUBLIC_NETWORK]:
    'Event: ML Platform Public Network',
};

export const ignoreDataKeys = ['__typename', 'reference'];

export const LOGIN_URL = '/login';
export const POST_LOGIN_REDIRECT_PATHNAME = '/assets';
export const LOGIN_VERIFICATION_URL = `${window.location.origin}/verify-login`;
