import loadable, { LoadableComponent } from '@loadable/component';

import { Integrations } from './pages/Integrations';
import VerifyLogin from './pages/Auth/VerifyLogin';

const SettingsHub = loadable(() => import('./pages/settings/settingsHub'));
const TrustFeed = loadable(() => import('./pages/TrustFeed/TrustFeed'));
const SaasService = loadable(
  () =>
    import('./pages/Dashboards/EntityDetails/ThirdartyAiService/saas-service')
);

const Risks = loadable(() => import('./pages/Discovery/Risks/Risks'));
const AllAssets = loadable(() => import('./pages/Discovery/Assets/AllAssets'));
const Cost = loadable(() => import('./pages/Discovery/Cost/Cost'));
const Dataflows = loadable(
  () => import('./pages/Discovery/Dataflows/Dataflows')
);
const Login = loadable(() => import('./pages/Auth/Login'));
const Policies = loadable(() => import('./pages/Policies/Policies'));
const Collectors = loadable(() => import('./pages/Collectors/Collectors'));
const PromptCompletion = loadable(
  () => import('./pages/PromptCompletion/PromptCompletion')
);
// const TrustDB = loadable(() => import('./pages/TrustDB/TrustDB'));

const DataSources = loadable(() => import('./pages/Datasources/Datasources'));
const NewManualDataSourceUpload = loadable(
  () =>
    import(
      './pages/Datasources/manualUploads/newManualUpload/NewManualDataSourceUpload'
    )
);

const Debug = loadable(() => import('./pages/Debug/Debug'));
const Playground = loadable(() => import('./pages/Playground/Playground'));

const Welcome = loadable(() => import('./pages/Auth/Welcome'));
const Dashboard = loadable(() => import('./pages/Dashboards/Dashboard'));
const Summary = loadable(() => import('./pages/Summary/Summary'));
const RouteRedirect = loadable(() => import('./pages/RouteRedirect'));
const AiPosture = loadable(() => import('./pages/aiPosture/aiPosture'));

const AuditLogs = loadable(() => import('./pages/auditLogs/AuditLogs'));

interface SRoute {
  path: string;
  component: ((props: unknown) => JSX.Element) | LoadableComponent<unknown>;
  exact?: boolean;
}

export const privateRoutesConfig: SRoute[] = [
  // URLS redirections for new urls
  {
    path: '/discovery/:page?/:subpage?',
    component: RouteRedirect,
  },
  { path: '/manage/:page?/:subpage?', component: RouteRedirect },
  { path: '/content/prompt-completion', component: RouteRedirect },
  //
  { path: '/assets/:page?', component: AiPosture },
  { path: '/risks', component: Risks },
  { path: '/dataflows', component: Dataflows },
  { path: '/cost', component: Cost },
  { path: '/dataSources/:page?', component: DataSources },
  {
    path: '/datasources/csv-uploads/new',
    component: NewManualDataSourceUpload,
  },
  { path: '/policies', component: Policies },
  { path: '/collectors', component: Collectors },
  { path: '/integrations', component: Integrations },
  { path: '/all-assets/:page?', component: AllAssets },
  { path: '/trust-db', component: Dataflows },
  { path: '/content', component: PromptCompletion },
  { path: '/debug', component: Debug },
  { path: '/playground', component: Playground },
  { path: '/trust-feed/:page?', component: TrustFeed },
  { path: '/dashboard/:page', component: Dashboard },
  { path: '/summary/:page?', component: Summary },
  { path: '/saas-service/:page?', component: SaasService },
  { path: '/audit-logs/:page?', component: AuditLogs },
  { path: '/settings', component: SettingsHub },
];

export const publicRoutesConfig: SRoute[] = [
  { path: '/login', component: Login },
  { path: '/verify-login', component: VerifyLogin },
  { path: '/welcome', component: Welcome },
];
