import PageHeader from 'root/src/layout/PageHeader';
import IntegrationItem, {
  IntegrationType,
  IntegrationItemProps,
} from './IntegrationItem';

function Integrations() {
  const integrationServices: IntegrationItemProps[] = [
    {
      icon: '/illustrations/jira.svg',
      name: 'Jira',
      provider: 'Atlassian',
      description:
        'Jira is a proprietary product developed by Atlassian that allows bug tracking, issue tracking and agile project management.',
      enabled: true,
      type: IntegrationType.JIRA,
    },
    {
      icon: '/illustrations/slack.svg',
      name: 'Slack',
      provider: 'Salesforce',
      description:
        'Slack is a cloud-based team communication platform developed by Slack Technologies, which has been owned by Salesforce since 2020. Slack uses a freemium model.',
      enabled: false,
      type: IntegrationType.SLACK,
    },
  ];
  return (
    <div className="flex flex-col pr-0 h-full w-full">
      <PageHeader
        title="Integration Hub"
        description="Third-party app integration available"
      />
      <div className="mt-4 bg-background border w-full h-full rounded-lg py-11 px-6">
        <div className="gap-4 grid grid-cols-4">
          {integrationServices.map((item: IntegrationItemProps, idx) => (
            <IntegrationItem key={idx} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Integrations;
