import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import type { ElementRef, ComponentPropsWithoutRef } from 'react';
import * as React from 'react';

import cn from '@/lib/utils';

const Collapsible = CollapsiblePrimitive.Root;

const { CollapsibleTrigger } = CollapsiblePrimitive;

// const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent

const CollapsibleContent = React.forwardRef<
  ElementRef<typeof CollapsiblePrimitive.Content>,
  ComponentPropsWithoutRef<typeof CollapsiblePrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <CollapsiblePrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down"
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </CollapsiblePrimitive.Content>
));
CollapsibleContent.displayName = CollapsiblePrimitive.Content.displayName;

export { Collapsible, CollapsibleTrigger, CollapsibleContent };
