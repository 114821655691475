export type ComponentId = string;
export type QueryId = string;

export interface QueryParam {
  key: string;
  value: string;
}

export interface Query {
  queryId: QueryId;
  queryType: string;
  endpointType: string;
  queryTemplate: string;
  description: string;
  entityType: number;
  queryParams: QueryParam[];
  sortParam: {
    direction: 'ASC' | 'DESC' | 'UNKNOWN_SORT_ORDER';
    key: string;
  };
}

export enum ComponentType {
  EMPTY_COMPONENT = 'EMPTY_COMPONENT',
  UNKNOWN_COMPONENT = 'UNKNOWN_COMPONENT',
  PAGE = 'PAGE',
  CARD_TOPOLOGY = 'CARD_TOPOLOGY',
  ASSET_INSIGHTS_TOPOLOGY = 'ASSET_INSIGHTS_TOPOLOGY',
  CARD = 'CARD',
  TABLE = 'TABLE',
  INSIGHT_LIST = 'INSIGHT_LIST',
  RISK_TOPOLOGY = 'RISK_TOPOLOGY',
  RISK_CARD = 'RISK_CARD',
  RISK_TABLE = 'RISK_TABLE',
}

export interface UiComponent {
  id: ComponentId;
  type: ComponentType;
  title: string;
  description: string;
  queries: Query[];
  group: string;
  childComponents: UiComponent[];
  container: boolean;
  linkedComponentIds: ComponentId[];
  linkedPageId: string;
  default: boolean;
}

export enum EntityTypeEnum {
  ENTITY_TYPE_UNKNOWN = 0,
  MACHINE = 1,
  PROCESS_INSTANCE = 2,
  NETWORK_IDENTITY = 3,
  NETWORK_INTERFACE = 4,
  USER = 5,
  GROUP = 6,
  SPAN = 7,
  TRACE = 8,
  CONVERSATION = 9,
  COMPONENT = 10,
  FRAMEWORK = 11,
  CHAIN_FUNCTION = 12,
  LLM_FUNCTION = 13,
  PROMPT_FUNCTION = 14,
  APPLICATION = 15,
  RETRIEVER_FUNCTION = 16,
  RETRIEVAL_STORE = 17,
  DNS_SERVICE = 18,
  VM = 19,
  AWS_VM = 1001,
  VPC = 20,
  AWS_VPC = 1002,
  IP = 21,
  CLOUD_USER = 22,
  AWS_CLOUD_USER = 1003,
  STORAGE_BUCKET = 23,
  S3_STORAGE_BUCKET = 1004,
  DOCUMENT_CONTAINER = 24,
  DOCUMENT = 25,
  VECTOR_DB = 26,
  VECTOR_DB_INDEX = 27,
  VECTOR_DB_COLLECTION = 28,
  VECTOR_DB_ENVIRONMENT = 29,
  PINECONE_VECTOR_DB = 1005,
  PINECONE_VECTOR_DB_INDEX = 1006,
  PINECONE_VECTOR_DB_COLLECTION = 1007,
  PINECONE_VECTOR_DB_ENVIRONMENT = 1008,
  ML_MODEL = 30,
  ML_DATA_SOURCE = 31,
  ML_MODEL_SOURCE = 32,
  ML_MODEL_ENDPOINT = 33,
  AWS_ML_MODEL_ENDPOINT = 1009,
  ML_MODEL_INSTANCE = 34,
  AWS_ML_MODEL_INSTANCE = 1010,
  COMPUTE_SPEC = 35,
  AWS_COMPUTE_SPEC = 1011,

  ML_PLATFORM = 50,
  ML_PLATFORM_POLICY = 51,
  ML_MODEL_DEPLOYMENT = 52,
  ENDPOINT = 55,
  CONTENT_POLICY = 53,
  CONTENT_FILTER = 54,
  PROMPT_CONTENT_FILTER = 56,
  COMPLETION_CONTENT_FILTER = 57,

  ML_MODEL_REPO = 36,
  HF_ML_MODEL_REPO = 1012,
  ML_DATA_REPO = 37,
  HF_ML_DATA_REPO = 1013,
  ML_MODEL_SET = 38,
  ML_DATA_SET = 39,

  // Trust Store Types
  AI_SERVICE_DEFINITION = 40,
  JAILBREAK_DEFINITION = 41,
  LLM_DEFINITION = 42,
  LLM_FAMILY_DEFINITION = 43,
  ML_MODEL_DEFINITION = 44,
  ML_DATA_SET_DEFINITION = 45,
  ML_LIBRARY_DEFINITION = 46,
  PUBLISHER_DEFINITION = 47,
  AI_API_ENDPOINT_DEFINITION = 48,

  AI_SERVICE = 49,
  LLM = 58,

  K8S_POD = 59,
  HTTP_SERVICE = 60,

  // IAM Types
  IAM_ROLE = 61,
  IAM_USER = 62,
  IAM_USER_GROUP = 63,
  IAM_POLICY = 64,
  IAM_PERMISSION = 65,

  // types for primitives (not for entities)
  PRIMITIVE_ENTITY_ID_NAME = 101, // special type for (entity id, name)
  PRIMITIVE_STRING = 102,
  PRIMITIVE_INT = 103,
  PRIMITIVE_BOOLEAN = 104,

  // Events starts from 100k (lets stick to this gap in IDs)
  EVENT_LICENSE_NON_COMMERCIAL = 100000, // Model
  EVENT_NON_ENCRYPTED_VECTOR_DB = 100001, // VectorDB
  EVENT_UNAPPROVED_MODEL = 100002, // Model
  EVENT_AI_SERVICE_POTENTIAL_DATA_LEAK = 100003, // AI Service
  EVENT_AI_SERVICE_BLOCKED_USAGE = 100004, // AI Service
  EVENT_VM_GPU_IDLE = 100005, // VMs
  EVENT_MODEL_DEPLOYED_FROM_UNTRUSTED_VENDOR = 100006, // Model
  EVENT_MODEL_TRAINED_ON_BIASED_DATA = 100007, // Model
  EVENT_MODEL_TRAINED_ON_TOXIC_DATA = 100008, // Model
  EVENT_MODEL_WITH_WELL_KNOWN_JAILBREAKS = 100009, // Model

  EVENT_VM_GPU = 100010, // VMs
  EVENT_MODEL_FROM_PRIVATE_MODEL_CARD = 100011, // Model
  EVENT_MODEL_WEAK_CONTENT_POLICY = 100012, // Model
  EVENT_ML_PLATFORM_DATA_ISOLATION_DISABLED = 100013, // ML Platform
  EVENT_ML_PLATFORM_NETWORK_ISOLATION_DISABLED = 100014, // ML Platform
  EVENT_ML_PLATFORM_PUBLIC_NETWORK = 100015, // ML Platform
}

export interface SingulrError {
  code: number;
  message: string;
  extended_message: string;
}
