import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CheckCheckIcon } from 'lucide-react';
import { useDescope } from '@descope/react-sdk';
import { LOGIN_URL } from '../../constants';

function VerifyLogin() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const descope = useDescope();

  const verifyToken = async (token: string) => {
    const { data, ok, error } = await descope.enchantedLink.verify(token);

    if (!ok && error) {
      setError(error.errorMessage ?? error.errorDescription);
      setTimeout(() => {
        navigate(LOGIN_URL);
      }, 3000);
    }

    if (ok && data) {
      setSuccess(true);
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, [token]);
  return (
    <div className=" h-full w-full flex justify-center items-center">
      {error ? (
        <p className=" text-red-500 text-lg">
          {error}. Redirecting you to the login screen to try again...
        </p>
      ) : null}
      {success ? (
        <p className=" text-lime-400 flex gap-4 items-center animate-pulse">
          <CheckCheckIcon className="w-11 h-11" />
          Your have been Logged In! Closing this window to continue...
        </p>
      ) : null}
    </div>
  );
}

export default VerifyLogin;
